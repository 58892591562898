const ENV = {
    WEB_URI: "https://api.sportsforce.io",
    WEB_URI_GRAPHQL: "https://api.sportsforce.io/graphql",
    DOWNLOAD_URL: "https://cdn.sportsforce.io",
    UPLOAD_URL: "https://api.sportsforce.io/api/Containers/imageupload/upload",
    apiKey : process.env.REACT_APP_SECRET_KEY,
    mapApiJs : "https://maps.googleapis.com/maps/api/js",
    WEB_ATHLETE_URL : "https://sportsforce.io"
}

export default ENV;

